<template>
  <div class="lineItem-container">
    <i-divider></i-divider>
    <i-row type="flex" v-if="lineItem">
      <i-col>
        <span> {{ lineItem.id | encodeLineItemId() }} </span>
      </i-col>
      <i-col>
        <i-divider type="vertical"></i-divider>
      </i-col>
      <i-col>
        <span>{{ lineItem.theme }}</span>
      </i-col>
      <i-col>
        <i-divider type="vertical"></i-divider>
      </i-col>
      <i-col>
        <span>{{ `${formatDate(lineItem.startDate)} - ${formatDate(lineItem.endDate)}` }}</span>
      </i-col>
      <i-col>
        <i-divider type="vertical"></i-divider>
      </i-col>
      <i-col>
        <span class="currency-symbol">{{currencySymbol}}</span>
        <span>{{ `${formatCurrency(lineItem.budget)} (${lineItem.pricingTypeReferenceValueName})` }}</span>
      </i-col>
    </i-row>

    <div>
      <i-table class="input-table" :columns="tblColumns" :data="tblData" disabled-hover border>
        <template slot-scope="{ index }" slot="language">
          <div>
            <i-select v-model="tblData[index].language"
          filterable :disabled="!isEdit"
          :class="{'error-border-plan-view': !tblData[index].isValidLanguage}"
          @on-change="validateInputLanguage(index)">
            <i-option v-for="option in languageOptions" :key="option.id" :value="option.id">{{ option.name
              }}</i-option>
          </i-select>
          </div>
        </template>
        <template slot-scope="{ index }" slot="adType">
          <div :class="{'error-border-plan-view': !tblData[index].isValidAdType}">
            <i-select v-model="tblData[index].adType"
            :disabled="!isEdit || (tblData[index].specifications ? true : false)"
            @on-change="validateInputAdType(index)"
            filterable>
              <i-optionGroup
                v-for="optionGroup in adTypeOptions"
                :label="optionGroup.optionGroupName"
                :key="optionGroup.optionGroupName"
              >
                <i-option
                  v-for="item in optionGroup.adTypes"
                  :value="item.id"
                  :key="item.id"
                >{{ item.displayName }}</i-option>
              </i-optionGroup>
            </i-select>
          </div>
        </template>
        <template slot-scope="{ index }" slot="specifications">
          <button :class="['spec-button truncate-text-container', { 'disabled-tag': !isEdit }]"
          :disabled="!tblData[index].adType || !isEdit"
          @click="openSpecificationsModal(index, tblData[index].specifications)">
            <span v-if="tblData[index].specifications">{{ formatSpecifications(tblData[index]) }}</span>
            <span v-else class="set-specifications"><i class="fa fa-sliders" aria-hidden="true"></i>Set Specifications</span>
          </button>
        </template>
        <template slot-scope="{ index }" slot="dueDate">
          <div :class="{'error-border-plan-view': !tblData[index].isValidDueDate}">
            <i-date-picker v-model="tblData[index].dueDate" format="yyyy-MM-dd"
            :disabled="!isEdit"
            :options="dateOptions"
            :clearable="false"
            :editable="false"
            @on-change="validateInputDueDate(index)"></i-date-picker>
          </div>
        </template>
        <template slot-scope="{ index }" slot="dueTime">
          <div>
            <i-time-picker v-model="tblData[index].dueTime" format="HH:mm"
            :clearable="false"
            :editable="false"
            :disabled="!isEdit"></i-time-picker>
          </div>
        </template>
        <template slot-scope="{ row }" slot="actions">
          <button :class="['delete-row-btn', { 'disabled-tag': !isEdit }]" @click="deleteRow(row)"><i
              class="fa fa-trash" aria-hidden="true"></i></button>
        </template>
      </i-table>
    </div>
    <div>
      <p @click="addRequirement" class="addRequirement-btn" v-if="isEdit">+ Add Requirement</p>
    </div>
    <div>
      <i-modal v-model="enableConfirmPopup" :footer-hide="false" :closable="false" :mask-closable="false"
        class-name="vertical-center-modal">
        <h4 slot="header">
          Delete Line Item
        </h4>
        <div>
          <p class="confirm-box-text-style">Are you sure you want to delete?</p>
        </div>
        <div slot="footer">
          <button type="button" class="btn create-btn btn-white button-style"
            v-on:click="confirmBoxCancel">Cancel</button>
          <button type="button" class="btn create-btn btn-success button-style" v-on:click="confirmAction">OK</button>
        </div>
      </i-modal>
    </div>
    <div>
      <i-specifications-modal
      :showModal="showSpecificationsModal"
      :specificationsData="specificationsModalData"
      :adTypeModalData="adTypeModalData"
      view="plan"
      @saveSpecifications = saveSpecifications($event)></i-specifications-modal>
    </div>
  </div>
</template>
<script>
import { Divider, Row, Col, Table, Select, DatePicker, TimePicker, Option, locale, Modal, OptionGroup } from 'iview';
import SpecificationsModal from './SpecificationsModal.vue';
import lang from 'iview/dist/locale/en-US'

locale(lang)
export default {
  components: {
    'i-divider': Divider,
    'i-row': Row,
    'i-col': Col,
    "i-table": Table,
    "i-select": Select,
    "i-date-picker": DatePicker,
    "i-time-picker": TimePicker,
    "i-option": Option,
    "i-modal": Modal,
    "i-optionGroup": OptionGroup,
    "i-specifications-modal": SpecificationsModal
  },
  data () {
    return {
      lineItem: null,
      tblColumns: [
        {
          title: 'Language',
          key: 'language',
          slot: 'language',
          align: 'center',
          width: 130
        },
        {
          title: 'Ad Type',
          key: 'adType',
          slot: 'adType',
          align: 'center',
          minWidth: 150
        },
        {
          title: 'Specifications',
          key: 'specifications',
          slot: 'specifications',
          align: 'center',
          width: 500
        },
        {
          title: 'Due Date',
          key: 'dueDate',
          slot: 'dueDate',
          align: 'center',
          minWidth: 130
        },
        {
          title: 'Due Time',
          key: 'dueTime',
          slot: 'dueTime',
          align: 'center',
          minWidth: 130
        },
        {
          title: 'Actions',
          key: 'actions',
          slot: 'actions',
          align: 'center',
          minWidth: 80
        }
      ],
      tblData: [],
      languageOptions: [],
      adTypeOptions: [],
      rowToDelete: null,
      enableConfirmPopup: false,
      lasttblIndex: 0,
      isEdit: true,
      showSpecificationsModal: false,
      specificationsModalData: {},
      adTypeModalData: {},
      inputIndex: 0,
      currencySymbol: '',
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      }
    }
  },
  watch: {
    tblData (newValue) {
      if (this.tblData.length >= 1) {
        this.$emit('linkLineItemMode', this.lineItem.id)
      }
      if (this.tblData.length <= 0) {
        this.$emit('unLinkLineItemMode', this.lineItem.id)
      }
    }
  },
  filters: {
    encodeLineItemId (value) {
      var encodedId = (parseInt(deltax.businessProfileId)).toString(36) + '-' + value + 'L'
      return encodedId;
    }
  },
  methods: {
    formatDate (dateString) {
      return new Date(dateString).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-IN').format(value);
    },
    deleteRow (row) {
      this.rowToDelete = row
      this.enableConfirmPopup = true;
    },
    confirmBoxCancel () {
      this.enableConfirmPopup = false
    },
    confirmAction () {
      const rowIndex = this.tblData.findIndex(item => item.index == this.rowToDelete.index);
      this.tblData.splice(rowIndex, 1);
      this.lasttblIndex = this.lasttblIndex - 1;
      this.enableConfirmPopup = false;
    },
    addRequirement () {
      this.tblData.push(
        {
          index: this.lasttblIndex + 1,
          id: null,
          language: 1,
          adType: null,
          specifications: null,
          dueDate: null,
          dueTime: null,
          status: 1,
          isValidLanguage: true,
          isValidAdType: true,
          isValidDueDate: true,
          isValidLinkLineItems: true
        }
      )
      this.lasttblIndex += 1;
    },
    openSpecificationsModal (index, specifications) {
      let adType = this.adTypeOptions
        .flatMap(option => option.adTypes) // Flatten adTypes arrays
        .find(adType => adType.id === this.tblData[index].adType);

      this.adTypeModalData = adType;
      this.specificationsModalData = specifications;
      this.showSpecificationsModal = true;
      this.inputIndex = index;
    },
    closeSpecificationsModal () {
      this.showSpecificationsModal = false;
      this.specificationsModalData = {};
      this.inputIndex = 0;
    },
    isValidJsonString (str) {
      if (typeof str !== 'string') return false;
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    },
    saveSpecifications (specifications) {
      if (specifications != "closeOnly") {
        this.tblData[this.inputIndex].specifications = specifications;
      }
      this.showSpecificationsModal = false;
      this.specificationsModalData = {};
      this.inputIndex = 0;
    },
    validateInputAdType (index) {
      if (!this.tblData[index].adType || this.tblData[index].adType == '') {
        this.tblData[index].isValidAdType = false;
      } else {
        this.tblData[index].isValidAdType = true;
      }
    },
    validateInputLanguage (index) {
      if (!this.tblData[index].language || this.tblData[index].language == '') {
        this.tblData[index].isValidLanguage = false;
      } else {
        this.tblData[index].isValidLanguage = true;
      }
    },
    validateInputDueDate (index) {
      if (!this.tblData[index].dueDate || this.tblData[index].dueDate == '') {
        this.tblData[index].isValidDueDate = false;
      } else {
        this.tblData[index].isValidDueDate = true;
      }
    },
    formatSpecifications (row) {
      const adType = this.adTypeOptions
        .flatMap(option => option.adTypes)
        .find(adType => adType.id === row.adType);

      if (!row.specifications || !adType || !adType.specifications) {
        return 'NA';
      }

      const adTypeSpecs = JSON.parse(adType.specifications);
      const specifications = {};
      adTypeSpecs.forEach(specGroup => {
        const type = specGroup.type;
        specifications[type] = {};

        specGroup.specifications.forEach(spec => {
          const key = spec.key;
          const displayName = spec.displayName;
          const value = (row.specifications[type] !== undefined && row.specifications[type] !== null) ? row.specifications[type][key] : undefined;
          specifications[type][displayName] = value;
        });
      });

      let result = '';
      for (const key in specifications) {
        const details = Object.entries(specifications[key])
          .map(([detailKey, value]) => `${detailKey}: ${value}`)
          .join(', ');
        result += `${key}: [${details}] `;
      }

      return result.trim();
    }
  }
}
</script>
<style scoped>
.addRequirement-btn {
  cursor: pointer;
  margin-top: 10px;
}

.disabled-tag {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f3f3f3 !important;
}

.delete-row-btn {
  font-size: large;
  width: 100%;
  height: 32px !important;
  border: none !important;
  background-color: white;
}

.spec-button {
  height: 100%;
  width: 100%;
  border: none;
  background-color: white;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineItem-container:not(:last-child) {
  margin-bottom: 15px;
}

.input-table {
  margin-top: 6px;
}

.truncate-text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.currency-symbol {
  margin-right: 1px;
}

::v-deep .error-border-plan-view .ivu-select-input,
.error-border-plan-view{
  border: 1px solid red !important;
}

::v-deep .ivu-col span {
  font-weight: 600;
  opacity: 0.79;
}

::v-deep .ivu-divider-horizontal {
  margin: 14px 0;
}

::v-deep .input-tbl td .ivu-table-cell {
  height: 100% !important;
}

::v-deep .input-tbl td .ivu-table-cell {
  height: 100% !important;
}

::v-deep .vue-treeselect__menu {
  z-index: 99999 !important;
}

::v-deep .input-table .ivu-table-body table tbody tr td:nth-child(3) .ivu-table-cell {
  height: 100% !important;
}

::v-deep .input-table .ivu-table-body table tbody tr td:nth-child(3) .ivu-table-cell div {
  height: 100% !important;
}

::v-deep .ivu-table-header table {
  width: 100% !important;
}

::v-deep .ivu-table-body table {
  width: 100% !important;
}

::v-deep .input-table .ivu-table-cell {
  padding: 0px !important;
}

::v-deep .input-table .ivu-table-cell span {
  font-weight: unset !important;
}

::v-deep .input-table .ivu-table td {
  height: 33px !important;
}

::v-deep .input-table .ivu-select-selection {
  border: none !important;
}

::v-deep .input-table .ivu-table th {
  height: 33px !important;
}

::v-deep .input-table .ivu-input-with-suffix {
  border: none !important;
}

::v-deep th .ivu-table-cell {
  font-weight: 500;
}

::v-deep li.ivu-select-item {
  text-align: left !important;
  width: 100%;
}

::v-deep .ivu-select-group-title {
  text-align: left !important;
  width: 100%;
}
</style>
