var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tbl-header"},[_c('p',{staticClass:"title-name"},[_vm._v(_vm._s(_vm.computeTblHeader))]),_c('button',{staticClass:"add-row-btn",on:{"click":function($event){return _vm.addRows()}}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"input-tbl"},[_c('i-table',{staticClass:"input-table",attrs:{"columns":_vm.tblColumns,"data":_vm.tblData,"disabled-hover":"","border":""},scopedSlots:_vm._u([{key:"language",fn:function(ref){
var index = ref.index;
return [_c('i-select',{class:{'error-border': !_vm.tblData[index].isValidLanguage},attrs:{"filterable":""},on:{"on-change":function($event){return _vm.validateInputLanguage(index)}},model:{value:(_vm.tblData[index].language),callback:function ($$v) {_vm.$set(_vm.tblData[index], "language", $$v)},expression:"tblData[index].language"}},_vm._l((_vm.languageOptions),function(option){return _c('i-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(_vm._s(option.name))])}),1)]}},{key:"adType",fn:function(ref){
var index = ref.index;
return [_c('i-select',{class:{'error-border': !_vm.tblData[index].isValidAdType},attrs:{"disabled":_vm.tblData[index].specifications ? true : false,"filterable":""},on:{"on-change":function($event){return _vm.validateInputAdType(index)}},model:{value:(_vm.tblData[index].adType),callback:function ($$v) {_vm.$set(_vm.tblData[index], "adType", $$v)},expression:"tblData[index].adType"}},_vm._l((_vm.adTypeOptions),function(optionGroup){return _c('i-optionGroup',{key:optionGroup.optionGroupName,attrs:{"label":optionGroup.optionGroupName}},_vm._l((optionGroup.adTypes),function(item){return _c('i-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.displayName))])}),1)}),1)]}},{key:"specifications",fn:function(ref){
var index = ref.index;
return [_c('button',{staticClass:"spec-button truncate-text-container",attrs:{"disabled":!_vm.tblData[index].adType},on:{"click":function($event){return _vm.openSpecificationsModal(index, _vm.tblData[index].specifications)}}},[(_vm.tblData[index].specifications)?_c('span',[_vm._v(_vm._s(_vm.formatSpecifications(_vm.tblData[index])))]):_c('span',{staticClass:"set-specifications"},[_c('i',{staticClass:"fa fa-sliders",attrs:{"aria-hidden":"true"}}),_vm._v("Set Specifications")])])]}},{key:"dueDate",fn:function(ref){
var index = ref.index;
return [_c('i-date-picker',{class:{'error-border': !_vm.tblData[index].isValidDueDate},attrs:{"options":_vm.dateOptions,"clearable":false,"editable":false,"format":"yyyy-MM-dd"},on:{"on-change":function($event){return _vm.validateInputDueDate(index)}},model:{value:(_vm.tblData[index].dueDate),callback:function ($$v) {_vm.$set(_vm.tblData[index], "dueDate", $$v)},expression:"tblData[index].dueDate"}})]}},{key:"dueTime",fn:function(ref){
var index = ref.index;
return [_c('i-time-picker',{attrs:{"clearable":false,"editable":false,"format":"HH:mm"},model:{value:(_vm.tblData[index].dueTime),callback:function ($$v) {_vm.$set(_vm.tblData[index], "dueTime", $$v)},expression:"tblData[index].dueTime"}})]}},{key:"linkLineItems",fn:function(ref){
var index = ref.index;
return [_c('div',{class:{'error-border': !_vm.tblData[index].isValidLinkLineItems}},[_c('tree-select',{attrs:{"multiple":true,"options":_vm.publisherLineItemOptions,"default-expand-level":1,"searchable":true,"value-consists-of":"LEAF_PRIORITY","limit":1,"clearable":false},on:{"select":_vm.calculateSummary,"deselect":_vm.removeFromSummary,"close":function($event){return _vm.validateInputLinkLineItems(index)}},model:{value:(_vm.tblData[index].linkLineItems),callback:function ($$v) {_vm.$set(_vm.tblData[index], "linkLineItems", $$v)},expression:"tblData[index].linkLineItems"}})],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('button',{staticClass:"delete-row-btn",on:{"click":function($event){return _vm.deleteRow(row)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])]}}])})],1),_c('div',{},[_c('i-modal',{attrs:{"footer-hide":false,"closable":false,"mask-closable":false,"class-name":"vertical-center-modal"},model:{value:(_vm.enableConfirmPopup),callback:function ($$v) {_vm.enableConfirmPopup=$$v},expression:"enableConfirmPopup"}},[_c('h4',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n            Delete Line Item\n          ")]),_c('div',[_c('p',{staticClass:"confirm-box-text-style"},[_vm._v("Are you sure you want to delete?")])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"btn create-btn btn-white button-style",attrs:{"type":"button"},on:{"click":_vm.confirmBoxCancel}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn create-btn btn-success button-style",attrs:{"type":"button"},on:{"click":_vm.confirmAction}},[_vm._v("OK")])])])],1),_c('div',[_c('i-specifications-modal',{attrs:{"showModal":_vm.showSpecificationsModal,"specificationsData":_vm.specificationsModalData,"adTypeModalData":_vm.adTypeModalData,"view":"plan"},on:{"saveSpecifications":function($event){return _vm.saveSpecifications($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }